import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import Particles from "preact-particles"
import { Fade, Bounce } from "react-reveal"
// import SplitText from "react-pose-text"
import { StaticImage } from "gatsby-plugin-image"
import Modal from "@components/modal/modal"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

// forms
import SandboxInviteForm from "@components/forms/sandboxInvite"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SeoComponent title="Home" description={siteDescription} />
        <div className="hero-section">
          <Particles
            className="hero-particles"
            options={{
              fpsLimit: 60,
              particles: {
                color: {
                  value: "#ffffff",
                },
                move: {
                  enable: true,
                  outMode: "out",
                  straight: false,
                  random: true,
                  speed: 1,
                  direction: "none",
                },
                number: {
                  value: 25,
                },
                opacity: {
                  random: true,
                  animation: {
                    enable: true,
                  },
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: 3,
                  random: true,
                  animation: {
                    enable: true,
                    speed: 2,
                    minimumValue: 0.3,
                  },
                },
              },
              detectRetina: true,
            }}
          />
          <div className="container">
            <h1 className="display-2 text-center text-white">
              <span className="text-gradient-blue d-inline-block">
                Digital Experiences
              </span>
              <br />
              as a Service
            </h1>
            <p className="h4 text-center text-white font-weight-normal">
              {/*<SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
                The answer to how people browse, shop and connect with your
                business
              </SplitText>*/}
              The answer to how people browse, shop and connect with your
              business
            </p>
            <Fade delay={500}>
              <div className="text-center pt-5">
                <a
                  href="https://www.webriq.com/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary btn-orange"
                >
                  Contact Us
                </a>
              </div>
            </Fade>
          </div>
          <div className="hero-svg-3" />
          <div className="hero-svg-2" />
          <div className="hero-svg-1" />
        </div>
        <div className="features-section bg-light">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-lg-6">
                <Fade>
                  <div className="feature-item rounded-lg h-100">
                    <span className="feature-num">01</span>
                    <h2 className="h4 pb-3 text-gradient-orange font-weight-bold">
                      Website
                    </h2>
                    <p>
                      On an open-source platform leveraging modern technologies
                      such as React and GraphQL. All blazing fast, secure, and
                      scalable from the get-go. And on a budget!
                    </p>
                    <StaticImage
                      quality={100}
                      placeholder="none"
                      src="../images/01-website.svg"
                      alt=""
                      width={200}
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-lg-6">
                <Fade>
                  <div className="feature-item rounded-lg h-100">
                    <span className="feature-num">02</span>
                    <h2 className="h4 pb-3 text-gradient-orange font-weight-bold">
                      Ecommerce
                    </h2>
                    <p>
                      Built on the same open-source platform and leveraging the
                      same technologies, it allows you to create content-first
                      and personalized experiences shoppers demand.
                    </p>
                    <StaticImage
                      quality={100}
                      placeholder="none"
                      src="../images/02-ecommerce.svg"
                      alt=""
                      width={200}
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-lg-6">
                <Fade>
                  <div className="feature-item rounded-lg h-100">
                    <span className="feature-num">03</span>
                    <h2 className="h4 pb-3 text-gradient-orange font-weight-bold">
                      Chatbots
                    </h2>
                    <p>
                      Pre-trained with content from your specific industry, the
                      Bot can understand your historical logs, search in your
                      knowledge base, or direct to human representatives.
                    </p>
                    <StaticImage
                      quality={100}
                      placeholder="none"
                      src="../images/03-chatbot.svg"
                      alt=""
                      width={200}
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-lg-6">
                <Fade>
                  <div className="feature-item rounded-lg h-100">
                    <span className="feature-num">04</span>
                    <h2 className="h4 pb-3 text-gradient-orange font-weight-bold">
                      Structured Content
                    </h2>
                    <p>
                      Content modules can be customized, reused, personalized
                      and displayed in different formats on your website, on
                      native apps, digital signage, and wearables.
                    </p>
                    <StaticImage
                      quality={100}
                      placeholder="none"
                      src="../images/04-structured.svg"
                      alt=""
                      width={200}
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-lg-6">
                <Fade>
                  <div className="feature-item rounded-lg h-100">
                    <span className="feature-num">05</span>
                    <h2 className="h4 pb-3 text-gradient-orange font-weight-bold">
                      Serverless Applications
                    </h2>
                    <p>
                      Webforms, payment forms, gated and premium content,
                      memberships, and paywalls are all served through our
                      serverless applications with AWS.
                    </p>
                    <StaticImage
                      quality={100}
                      placeholder="none"
                      src="../images/05-serverless.svg"
                      alt=""
                      width={200}
                    />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="py-10">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 col-lg-5">
                <Bounce className="h-100">
                  <div className="p-5 rounded">
                    <div className="d-flex align-items-center mb-4">
                      <StaticImage
                        placeholder="none"
                        width={40}
                        src="../images/crossmark.svg"
                      />
                      <h2 className="ml-2 mb-0 text-uppercase font-weight-bold text-gradient-orange">
                        The Old Way
                      </h2>
                    </div>
                    <ul className="list-unstyled">
                      <li>Fixed Templates</li>
                      <li>Endless expensive mockups</li>
                      <li>Build and disappear</li>
                      <li>Non-transparent invoices</li>
                      <li>Drop a support ticket, see what happens</li>
                      <li>Complicated devops</li>
                      <li>Complicated plugin system</li>
                    </ul>
                  </div>
                </Bounce>
              </div>
              <div className="col-md-6 col-lg-5">
                <Bounce delay={500} className="h-100">
                  <div className="p-5 rounded bg-primary shadow">
                    <div className="d-flex align-items-center mb-4">
                      <StaticImage
                        placeholder="none"
                        width={40}
                        src="../images/checkmark.svg"
                      />
                      <h2 className="ml-2 mb-0 text-uppercase font-weight-bold text-white">
                        The New Way
                      </h2>
                    </div>
                    <ul className="list-unstyled text-white">
                      <li>Purpose based design</li>
                      <li>Component based design with content</li>
                      <li>Analyze and improve continuously</li>
                      <li>Simple and predictable monthly pricing</li>
                      <li>Chat and speak to humans</li>
                      <li>No devops and serverless</li>
                      <li>No plugins, only API’s </li>
                    </ul>
                  </div>
                </Bounce>
              </div>
            </div>
            <div className="pt-10 text-center">
              <Fade up>
                <Link
                  to="/is-webriq-glue-for-you/"
                  className="btn btn-primary btn-orange"
                >
                  Is WebriQ GLUE for YOU?
                </Link>
              </Fade>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <Fade up>
              <div className="px-3 px-md-5 building-blocks-section rounded bg-light">
                <h2 className="text-gradient-blue font-weight-bold text-center mb-5">
                  Our Building Blocks
                </h2>
                <div className="row justify-content-around">
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-3 text-right">
                          <StaticImage
                            placeholder="none"
                            width={40}
                            imgClassName="d-inline-block"
                            src="../images/cloud-network.svg"
                          />
                        </div>
                        <div className="col-9">
                          <p>Fully managed digital platform in the cloud</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-3 text-right">
                          <StaticImage
                            placeholder="none"
                            width={40}
                            imgClassName="d-inline-block"
                            src="../images/software.svg"
                          />
                        </div>
                        <div className="col-9">
                          <p>
                            Software platform to manage, deliver and optimize
                            experiences consistently across every digital
                            touchpoint
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-3 text-right">
                          <StaticImage
                            placeholder="none"
                            width={40}
                            imgClassName="d-inline-block"
                            src="../images/link.svg"
                          />
                        </div>
                        <div className="col-9">
                          <p>
                            Headless content management with Structured Content
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-3 text-right">
                          <StaticImage
                            placeholder="none"
                            width={40}
                            imgClassName="d-inline-block"
                            src="../images/ecommerce.svg"
                          />
                        </div>
                        <div className="col-9">
                          <p>Headless eCommerce</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-3 text-right">
                          <StaticImage
                            placeholder="none"
                            width={40}
                            imgClassName="d-inline-block"
                            src="../images/api.svg"
                          />
                        </div>
                        <div className="col-9">
                          <p>
                            API’s that can deliver content to any required
                            channel, including wearables and digital signage
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-3 text-right">
                          <StaticImage
                            placeholder="none"
                            width={40}
                            imgClassName="d-inline-block"
                            src="../images/process.svg"
                          />
                        </div>
                        <div className="col-9">
                          <p>
                            Operations, deployment, back-ups, upgrades, scaling,
                            and security is left to WebriQ
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="pb-10">
          <div className="container">
            <Fade>
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="text-center pricing-cta-wrapper text-white rounded shadow">
                    <h2 className="display-5 font-weight-bold">Pricing</h2>
                    <div className="my-4">
                      <p className="lead">
                        WebriQ GLUE has a unit price of $1,000
                      </p>
                      <ul className="pricing-bullets text-left text-sm-center">
                        <li>No upfront fees</li>
                        <li>Scale up or down your units</li>
                        <li>Cancel at any moment</li>
                      </ul>
                    </div>
                    <Link to="/pricing/" className="btn btn-primary btn-orange">
                      Pricing
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="pb-5">
          <div className="container text-center">
            <div className="glue-logo-dark">
              <StaticImage
                placeholder="none"
                src="../images/webriq-glue-logo.png"
                alt="WebriQ"
                width={180}
              />
            </div>
            <div className="glue-logo-light">
              <StaticImage
                placeholder="none"
                src="../images/webriq-glue-logo-dark.png"
                alt="WebriQ"
                width={180}
              />
            </div>

            <Bounce>
              <div className="mt-3 mb-5">
                <Modal
                  dialogClassName="video-modal"
                  html="Get Your Sandbox Invite"
                  btnClassName="btn btn-primary"
                >
                  <div className="p-3 p-md-5">
                    <h3 className="font-weight-bold text-gradient-blue">
                      Get your invite to our HEADLESS CONTENT MANAGEMENT SANDBOX
                      ACCOUNT and just write away.
                    </h3>
                    <LazyLoadComponent>
                      <SandboxInviteForm />
                    </LazyLoadComponent>
                  </div>
                </Modal>
              </div>
            </Bounce>
            <Fade>
              <LazyLoadComponent>
                <div className="d-flex justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>React</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/react-logomark.svg"
                      width={75}
                      alt="React"
                      imgClassName="logo-mark-hover p-2"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Gatsby</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/gatsby-logomark.svg"
                      width={75}
                      alt="Gatsby"
                      imgClassName="logo-mark-hover p-2"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>HTML5</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/html5-logo.svg"
                      width={75}
                      alt="HTML5"
                      imgClassName="logo-mark-hover p-2"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Node.js</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/nodejs-logo.svg"
                      width={75}
                      alt="Node.js"
                      imgClassName="logo-mark-hover p-2"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Git</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/git-logo.svg"
                      width={75}
                      alt="Git"
                      imgClassName="logo-mark-hover p-2"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Github</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/github-logo.svg"
                      width={75}
                      alt="Github"
                      imgClassName="logo-mark-hover github p-2"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Netlify</Tooltip>}
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/netlify-logomark.svg"
                      width={75}
                      alt="Netlify"
                      imgClassName="logo-mark-hover p-2"
                    />
                  </OverlayTrigger>
                </div>
              </LazyLoadComponent>
            </Fade>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
