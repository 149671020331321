import React from "react"
import { Row, Col } from "react-bootstrap"

const SandBoxInviteForm = () => (
	<div id="crmWebToEntityForm">
		<meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
		<form
			action="https://crm.zoho.com/crm/WebToLeadForm"
			name="WebToLeads3371756000001229022"
			method="POST"
			acceptCharset="UTF-8"
		>
			<input
				type="text"
				style={{ display: "none" }}
				name="xnQsjsdp"
				defaultValue="b19f04dc9080dfd50a59b865053f951acaceb973cee42e9bd7e36fa287c7aedb"
			/>
			<input type="hidden" name="zc_gad" id="zc_gad" defaultValue />
			<input
				type="text"
				style={{ display: "none" }}
				name="xmIwtLD"
				defaultValue="f1b44266ba4241dd782a5d4a2a574944fb68220bc18b2d6f76bfcbc57ea11d7e"
			/>
			<input
				type="text"
				style={{ display: "none" }}
				name="actionType"
				defaultValue="TGVhZHM="
			/>
			<input
				type="text"
				style={{ display: "none" }}
				name="returnURL"
				defaultValue="https://glue.webriq.com"
			/>
			<br />
			<Row>
				<Col xs={12}>
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							maxLength={100}
							name="Company"
							id="InputCompany"
							required
							placeholder="Company"
						/>
						<label htmlFor="InputCompany">
							Company<span className="text-danger">*</span>
						</label>
					</div>
				</Col>
				<Col xs={12}>
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							maxLength={80}
							name="Last Name"
							id="InputFullname"
							required
							placeholder="First and Last Name"
						/>
						<label htmlFor="InputFullname">
							First and Last Name<span className="text-danger">*</span>
						</label>
					</div>
				</Col>
				<Col xs={12}>
					<div className="form-group">
						<input
							className="form-control"
							type="email"
							maxLength={100}
							name="Email"
							id="InputEmail"
							required
							placeholder="Business Email"
						/>
						<label htmlFor="InputEmail">
							Business Email<span className="text-danger">*</span>
						</label>
					</div>
				</Col>
				<Col xs={12}>
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							maxLength={255}
							name="Website"
							id="InputWebsite"
							required
							placeholder="Website"
						/>
						<label htmlFor="InputWebsite">
							Website<span className="text-danger">*</span>
						</label>
					</div>
				</Col>
				<Col xs={12}>
					<div className="form-group">
						<input
							className="form-control"
							type="number"
							maxLength={9}
							name="No of Employees"
							id="InputEmployees"
							required
							placeholder="No. of Employees"
						/>
						<label htmlFor="InputEmployees">
							No. of Employees<span className="text-danger">*</span>
						</label>
					</div>
				</Col>
			</Row>
			<div className="d-flex justify-content-start mt-3">
				<button
					name="submit"
					id="formsubmit"
					type="submit"
					className="btn btn-primary hvr-shadow mx-0"
				>
					Submit
				</button>
			</div>
		</form>
	</div>
)

export default SandBoxInviteForm
